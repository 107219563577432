import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/ProductPage.css'; // Import the new CSS file
import logo from '../images/Home/n7logo.jpg'; // Adjust this path as necessary
import { db, storage } from '../config/firebase'; // Import from your configured firebase
import { collection, getDocs } from 'firebase/firestore'; // Import these functions
import { ref, getDownloadURL } from 'firebase/storage'; // Import these functions
import AdminPanel from '../components/AdminPanel'; // Import the AdminPanel component

const EventsPage = () => {
  const [products, setProducts] = useState([]);
  const [imageUrl, setImageUrl] = useState('');
  const [isAdminPanelOpen, setIsAdminPanelOpen] = useState(false); // State to manage modal visibility

  useEffect(() => {
    // Fetch all products from Firestore
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'events')); // Get all documents in the collection
        const productsArray = querySnapshot.docs.map(doc => doc.data()); // Map through documents to get data
        // Define custom sort order
        const sortOrder = [
          'The Basics',
          'Steaks (8oz to 10oz Serving Size)',
          'Roasts (2lb to 4lb size)',
          'Others',
          'Summer Boxes'
        ];
        // Sort products by custom order
        productsArray.sort((a, b) => {
          const typeA = a.type || '';
          const typeB = b.type || '';
          return sortOrder.indexOf(typeA) - sortOrder.indexOf(typeB);
        });
        setProducts(productsArray); // Set products state
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    // Fetch image URL from Firebase Storage
    const fetchImage = async () => {
      try {
        const imageRef = ref(storage, 'products/1000005593.jpg'); // Create a reference to the image
        const url = await getDownloadURL(imageRef); // Get the download URL
        setImageUrl(url);
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };

    fetchProducts();
    fetchImage();
  }, []);

  const openAdminPanel = () => setIsAdminPanelOpen(true);
  const closeAdminPanel = () => setIsAdminPanelOpen(false);

  // Group products by type
  const groupedProducts = products.reduce((acc, product) => {
    const type = product.type || 'Others'; // Default to 'Others' if type is not defined
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(product);
    return acc;
  }, {});

  return (
    <div className="product-page">
      <header className="home-header">
        <div className="home-logo">
          <img src={logo} alt="Company logo" />
        </div>
        <nav className="home-nav">
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/products">Products</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/events">Events</Link>
        </nav>
      </header>

      <section className="product-hero">
        <div className="hero-content">
          <h1><b>Events</b></h1>
        </div>
      </section>

      <section className="product-section">
        <div className="product-content">
          {Object.keys(groupedProducts).map((type, index) => (
            <div key={index} className="product-category">
              <div className="product-list">
                {groupedProducts[type].map((product, idx) => (
                  <div key={idx} className="product-card">
                    <p><b>{product.name}</b></p>
                    <p><b></b> {product.note}</p>
                    <p><b>Price:</b> ${product.price}</p>
                    <p><b>Per:</b> {product.weight}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
          {imageUrl && <img src={imageUrl} alt="Welcome" className="welcome-image" />}
        </div>
      </section>

      <footer className="home-footer">
        <p>&copy; 2024 N7 Cattle Company. All Rights Reserved.</p>
        <button onClick={openAdminPanel} className="admin-button">Admin</button>
      </footer>

      {isAdminPanelOpen && <AdminPanel onClose={closeAdminPanel} />}
    </div>
  );
};

export default EventsPage;