import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../config/firebase';
import { doc, getDoc, deleteDoc, addDoc, collection, getDocs, updateDoc } from 'firebase/firestore';
import '../styles/AdminPanel.css'; // Ensure you have this CSS file
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { storage } from '../config/firebase'; // Adjust path if needed
import ForgotPassword from '../components/ForgotPassword';


const AdminPanel = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [user, setUser] = useState(null);
  const [selectedPage, setSelectedPage] = useState('home'); // Track selected page
  const [newContent, setNewContent] = useState({
    header1: '',
    header2: '',
    paragraph1: '',
    paragraph2: '',
    paragraph3: '',
    paragraph4: '',
    paragraph5: '',
    paragraph6: '',
  });

  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({ name: '', price: '', type: '', weight: '', note: '' });
  const [contacts, setContacts] = useState([]);
  const [imageUrl, setImageUrl] = useState('');
  const [aboutImageUrl, setAboutImageUrl] = useState('');
  const [heritageImageUrl, setHeritageImageUrl] = useState(''); // State for the second image


  const handleHeritageImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const imageRef = ref(storage, 'title/image-heritage.jpg');
        await uploadBytes(imageRef, file);
        const url = await getDownloadURL(imageRef);
        setHeritageImageUrl(url);
        alert('Heritage Image updated successfully!');
      } catch (error) {
        console.error('Error uploading heritage image:', error);
      }
    }
  };

  const handleCloseForgotPassword = () => {
    setShowForgotPassword(false);
  };
  
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const imageRef = ref(storage, 'home/image-welcome.jpg');
        await uploadBytes(imageRef, file);
        const url = await getDownloadURL(imageRef);
        setImageUrl(url);
        alert('Image updated successfully!');
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  const handleAboutImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const imageRef = ref(storage, 'about/image-small-team.jpg'); // Create a reference to the image
        await uploadBytes(imageRef, file); // Upload the file
        const url = await getDownloadURL(imageRef); // Get the download URL
        setAboutImageUrl(url);
        alert('Image uploaded successfully!');
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };


  // Monitor authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  // Fetch document content or products based on selected page
  useEffect(() => {
    const fetchDocument = async () => {
      const defaultContent = {
        header1: 'Default Header 1',
        header2: 'Default Header 2',
        paragraph1: 'Default Paragraph 1',
        paragraph2: 'Default Paragraph 2',
        paragraph3: 'Default Paragraph 3',
        paragraph4: 'Default Paragraph 4',
        paragraph5: 'Default Paragraph 5',
        paragraph6: 'Default Paragraph 6',
      };

      let docId = ''; 

      if (selectedPage === 'home') {
        docId = '3jXcah4wmUm8QQmYMxWq';
      } else if (selectedPage === 'about') {
        docId = '2erlceupKexfgyCuVUVP';
      } else if (selectedPage === 'products') {
        fetchProducts();
        return;
      }

      if (docId) {
        try {
          const docRef = doc(db, selectedPage, docId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setNewContent(docSnap.data());
          } else {
            console.log('No such document! Using default content.');
            setNewContent(defaultContent);
          }
        } catch (error) {
          console.error('Error fetching document:', error);
          setNewContent(defaultContent);
        }
      }
    };
    const fetchImage = async () => {
      try {
        let welcomeImageRef, heritageImageRef, aboutImageRef;
        if (selectedPage === 'home') {
          welcomeImageRef = ref(storage, 'home/image-welcome.jpg');
          heritageImageRef = ref(storage, 'title/image-heritage.jpg');
        } else if (selectedPage === 'about') {
          aboutImageRef = ref(storage, 'about/image-small-team.jpg');
        }
    
        if (welcomeImageRef) {
          const welcomeUrl = await getDownloadURL(welcomeImageRef);
          setImageUrl(welcomeUrl);
        }
        
        if (heritageImageRef) {
          const heritageUrl = await getDownloadURL(heritageImageRef);
          setHeritageImageUrl(heritageUrl);
        }
        
        if (aboutImageRef) {
          const aboutUrl = await getDownloadURL(aboutImageRef);
          setAboutImageUrl(aboutUrl);
          console.log('About Image URL:', aboutUrl); // Debugging line
        }
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchDocument();
    fetchImage();
  }, [selectedPage]);

  // Fetch products from Firestore
  const fetchProducts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productsArray = [];
      querySnapshot.forEach((doc) => {
        productsArray.push({ id: doc.id, ...doc.data() });
      });
      setProducts(productsArray);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  // Handle sign-in
  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error('Error signing in:', error);
    }
  };

  // Handle sign-out
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      onClose(); // Close the modal on sign out
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Handle product deletion
  const handleDeleteProduct = async (productId) => {
    try {
      const productRef = doc(db, 'products', productId);
      await deleteDoc(productRef);
      fetchProducts(); // Refresh product list
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  // Handle adding a new product
  const handleAddProduct = async () => {
    try {
      await addDoc(collection(db, 'products'), newProduct);
      setNewProduct({ name: '', price: '', type: '', weight: '', note: '' }); // Reset form
      fetchProducts(); // Refresh product list
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };
  
  const fetchContacts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'contacts'));
      const contactsArray = [];
      querySnapshot.forEach((doc) => {
        contactsArray.push({ id: doc.id, ...doc.data() });
      });
      setContacts(contactsArray);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };
  
  const handleUpdateContact = async (contactId, updatedContact) => {
    try {
      const docRef = doc(db, 'contacts', contactId);
      await updateDoc(docRef, updatedContact);
      alert('Contact information updated!');
      fetchContacts(); // Refresh the contact list
    } catch (error) {
      console.error('Error updating contact:', error);
    }
  };
  
  // Fetch contact info when 'contact' page is selected
  useEffect(() => {
    if (selectedPage === 'contact') {
      fetchContacts();
    }
  }, [selectedPage]);
  

  return (
    <div className="admin-modal">
      <div className="admin-modal-content">
        {!user ? (
          <div className="admin-login">
            <h2>Sign In</h2>
            <form onSubmit={handleSignIn}>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
              <button type="submit">Sign In</button>
              <button type="button" onClick={() => setShowForgotPassword(true)}>Forgot Password?</button>
              <button className="close-btn" onClick={onClose}>Close</button>
            </form>
            {showForgotPassword && <ForgotPassword onClose={handleCloseForgotPassword} />}
          </div>
        ) : (
          <div className="admin-dashboard">
            <nav className="admin-nav">
              <div className="admin-nav-brand">Admin Dashboard</div>
              <ul className="admin-nav-links">
                <li onClick={() => setSelectedPage('home')}>Home</li>
                <li onClick={() => setSelectedPage('about')}>About</li>
                <li onClick={() => setSelectedPage('products')}>Products</li>
                <li onClick={() => setSelectedPage('contact')}>Contact</li>
              </ul>
            </nav>
            <div className="update-section">
            {selectedPage === 'about' && (
  <>
    <h3>Update About Page</h3>
    <textarea
                    placeholder="Header 1"
                    value={newContent.header1 || ''}
                    onChange={(e) => setNewContent({ ...newContent, header1: e.target.value })}
                  />
                  <p>Header 2</p>
                  <textarea
                    placeholder="Header 2"
                    value={newContent.header2 || ''}
                    onChange={(e) => setNewContent({ ...newContent, header2: e.target.value })}
                  />
                  <p>Paragraph 1</p>
                  <textarea
                    placeholder="Paragraph 1"
                    value={newContent.paragraph1 || ''}
                    onChange={(e) => setNewContent({ ...newContent, paragraph1: e.target.value })}
                  />
                  <p>Paragraph 2</p>
                  <textarea
                    placeholder="Paragraph 2"
                    value={newContent.paragraph2 || ''}
                    onChange={(e) => setNewContent({ ...newContent, paragraph2: e.target.value })}
                  />
                  <p>Paragraph 3</p>
                  <textarea
                    placeholder="Paragraph 3"
                    value={newContent.paragraph3 || ''}
                    onChange={(e) => setNewContent({ ...newContent, paragraph3: e.target.value })}
                  />
                  <p>Paragraph 4</p>
                  <textarea
                    placeholder="Paragraph 4"
                    value={newContent.paragraph4 || ''}
                    onChange={(e) => setNewContent({ ...newContent, paragraph4: e.target.value })}
                  />
                  <p>Paragraph 5</p>
                  <textarea
                    placeholder="Paragraph 5"
                    value={newContent.paragraph5 || ''}
                    onChange={(e) => setNewContent({ ...newContent, paragraph5: e.target.value })}
                  />
                  <p>Paragraph 6</p>
                  <textarea
                    placeholder="Paragraph 6"
                    value={newContent.paragraph6 || ''}
                    onChange={(e) => setNewContent({ ...newContent, paragraph6: e.target.value })}
                  />
                  <p>Current Image at the bottom:</p>
    {aboutImageUrl && <img src={aboutImageUrl} alt="About" style={{ width: '400px' }} />}
    <input type="file" onChange={handleAboutImageUpload} />
                </>
              )}
              {selectedPage === 'home' && (
  <>
    <h3>Update Home Page</h3>
    <p>Current Title Image:</p>
      {heritageImageUrl && <img src={heritageImageUrl} alt="Heritage Image" style={{ maxWidth: '100%', height: 'auto' }} />}
      <input type="file" accept="image/*" onChange={handleHeritageImageUpload} />
    <p>Paragraph 1</p>
    <textarea
      placeholder="Paragraph 1"
      value={newContent.paragraph1 || ''}
      onChange={(e) => setNewContent({ ...newContent, paragraph1: e.target.value })}
    />
    <p>Paragraph 2</p>
    <textarea
      placeholder="Paragraph 2"
      value={newContent.paragraph2 || ''}
      onChange={(e) => setNewContent({ ...newContent, paragraph2: e.target.value })}
    />
    <p>Paragraph 3</p>
    <textarea
      placeholder="Paragraph 3"
      value={newContent.paragraph3 || ''}
      onChange={(e) => setNewContent({ ...newContent, paragraph3: e.target.value })}
    />
    <div>
      <p>Current Image at the bottom:</p>
      {imageUrl && <img src={imageUrl} alt="Home Page" style={{ maxWidth: '100%', height: 'auto' }} />}
      <input type="file" accept="image/*" onChange={handleImageUpload} />
    </div>
  </>
)}
              {selectedPage === 'products' && (
                <>
                  <h3>Manage Products</h3>
                  <div className="products-list">
                    {products.map((product) => (
                      <div key={product.id} className="product-item">
                        <h4>{product.name}</h4>
                        <p>Price: {product.price}</p>
                        <p>Type: {product.type}</p>
                        <p>Weight: {product.weight}</p>
                        <p>Note: {product.note}</p>
                        <button onClick={() => handleDeleteProduct(product.id)}>Delete</button>
                      </div>
                    ))}
                  </div>
                  <div className="add-product">
                    <h3>Add New Product</h3>
                    <input
                      type="text"
                      value={newProduct.name}
                      onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
                      placeholder="Product Name"
                    />
                    <input
                      type="text"
                      value={newProduct.price}
                      onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
                      placeholder="Product Price Per Weight"
                    />
                    <select
                      value={newProduct.type}
                      onChange={(e) => setNewProduct({ ...newProduct, type: e.target.value })}
                    >
                      <option value="The Basics">The Basics</option>
                      <option value="Roasts (2lb to 4lb size)">Roasts (2lb to 4lb size)</option>
                      <option value="Others">Others</option>
                      <option value="Steaks (8oz to 10oz Serving Size)">Steaks (8oz to 10oz Serving Size)</option>
                      <option value="Summer Boxes">Summer Boxes</option>
                    </select>
                    <select
                      value={newProduct.weight}
                      onChange={(e) => setNewProduct({ ...newProduct, weight: e.target.value })}
                    >
                      <option value="lb">lb</option>
                      <option value="kg">kg</option>
                    </select>
                    <textarea
                      value={newProduct.note}
                      onChange={(e) => setNewProduct({ ...newProduct, note: e.target.value })}
                      placeholder="Product Notes (Sausage Flavours, etc)"
                    />
                    <button onClick={handleAddProduct}>Add Product</button>
                  </div>
                </>
              )}
            </div>
            
          </div>
        )}
        {selectedPage === 'contact' && (
  <>
    <h3>Manage Contacts</h3>
    <div className="contacts-list">
      {contacts.map((contact) => (
        <div key={contact.id} className="contact-item">
          <h4>{contact.name}</h4>
          <p>Address: <input
            type="text"
            value={contact.address}
            onChange={(e) => setContacts(contacts.map(c => c.id === contact.id ? { ...c, address: e.target.value } : c))}
          /></p>
          <p>Email: <input
            type="email"
            value={contact.email}
            onChange={(e) => setContacts(contacts.map(c => c.id === contact.id ? { ...c, email: e.target.value } : c))}
          /></p>
          <p>Number: <input
            type="text"
            value={contact.number}
            onChange={(e) => setContacts(contacts.map(c => c.id === contact.id ? { ...c, number: e.target.value } : c))}
          /></p>
          <button onClick={() => handleUpdateContact(contact.id, contact)}>Update</button>
        </div>
        
      ))}
    </div>
    
    <button className="sign-out-btn" onClick={handleSignOut}>Sign Out</button>
      <button className="close-btn" onClick={onClose}>Close</button>
  </>
)}
      </div>
    </div>
  );
};

export default AdminPanel;