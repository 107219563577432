import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage'; // Import Firebase Storage

const firebaseConfig = {
  apiKey: "AIzaSyBZ64-UAS3epNWEd_MvkujAZxbBHDroH_g",
  authDomain: "n7cattlecompany-e48cd.firebaseapp.com",
  projectId: "n7cattlecompany-e48cd",
  storageBucket: "n7cattlecompany-e48cd.appspot.com",
  messagingSenderId: "382434677282",
  appId: "1:382434677282:web:3704316c1dbea7dd07707b",  
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { db, auth, storage, doc, getDoc }; // Export storage
