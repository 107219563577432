import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Home.css';
import logo from '../images/Home/n7logo.jpg'; // Adjust this path as necessary
import { db, storage } from '../config/firebase'; // Import from your configured firebase
import { doc, getDoc } from 'firebase/firestore'; // Import these functions
import { ref, getDownloadURL } from 'firebase/storage'; // Import these functions
import AdminPanel from '../components/AdminPanel'; // Import the AdminPanel component

const HomePage = () => {
  const [content, setContent] = useState({
    paragraph1: '',
    paragraph2: '',
    paragraph3: ''
  });
  const [imageUrl, setImageUrl] = useState('');
  const [heroImageUrl, setHeroImageUrl] = useState(''); // State for home-hero background image
  const [isAdminPanelOpen, setIsAdminPanelOpen] = useState(false); // State to manage modal visibility
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    // Fetch content from Firestore
    const fetchContent = async () => {
      try {
        const docRef = doc(db, 'home', '3jXcah4wmUm8QQmYMxWq'); // Get document reference
        const docSnap = await getDoc(docRef); // Get document snapshot
        if (docSnap.exists()) {
          setContent(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    // Fetch image URL from Firebase Storage for welcome image
    const fetchImage = async () => {
      try {
        const imageRef = ref(storage, 'home/image-welcome.jpg'); // Create a reference to the image
        const url = await getDownloadURL(imageRef); // Get the download URL
        setImageUrl(url);
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };

    // Fetch image URL from Firebase Storage for home-hero background
    const fetchHeroImage = async () => {
      try {
        const heroImageRef = ref(storage, 'title/image-heritage.jpg'); // Path to your home-hero image in Firebase Storage
        const heroUrl = await getDownloadURL(heroImageRef); // Get the download URL
        setHeroImageUrl(heroUrl);
      } catch (error) {
        console.error('Error fetching hero image:', error);
      }
    };

    fetchContent();
    fetchImage();
    fetchHeroImage();
  }, []);

  const openAdminPanel = () => setIsAdminPanelOpen(true);
  const closeAdminPanel = () => setIsAdminPanelOpen(false);

  return (
    <div className="home">
      <header className="home-header">
        <div className="home-logo">
          <img src={logo} alt="Company logo" />
        </div>
        <nav className="home-nav">
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/products">Products</Link>
          <Link to="/contact">Contact</Link>
        </nav>
      </header>

      <section 
        className="home-hero" 
        style={{ backgroundImage: `url(${heroImageUrl})` }}
      >
        <div className="hero-content">
          <h1><b>N7 Cattle Company</b></h1>
          <p><i>Premium Black Angus Beef</i></p>
        </div>
      </section>

      <section className="home-content">
        <div className="content-wrapper">
          <div className="paragraphs">
          <h1><b><i>{content.header}</i></b></h1>
            <p><i>{content.paragraph1}</i></p>
            <p><i>{content.paragraph2}</i></p>
            <p><i>{content.paragraph3}</i></p>
          </div>
          {imageUrl && <img src={imageUrl} alt="Welcome" className="welcome-image" />}
        </div>
      </section>

      <footer className="home-footer">
        <p>&copy; {currentYear} N7 Cattle Company. All Rights Reserved.</p>
        <p>
          Powered by <a href="https://www.ultimateappsca.info" target="_blank" rel="noopener noreferrer">UltimateAppsCA</a>
        </p>
        <button onClick={openAdminPanel} className="admin-button">Admin</button>
      </footer>

      {isAdminPanelOpen && <AdminPanel onClose={closeAdminPanel} />}
    </div>
  );
};

export default HomePage;