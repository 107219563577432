import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import '../styles/ForgotPassword.css'; // Import the CSS for styling

const ForgotPassword = ({ onClose }) => { // Accept onClose prop
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const auth = getAuth(); // Initialize auth instance

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      await sendPasswordResetEmail(auth, email); // Send password reset email
      setMessage('Password reset email sent!');
    } catch (err) {
      console.error(err); // Log error to the console
      setError(err.message || 'Error sending password reset email. Please try again.');
    }
  };

  return (
    <div className="admin-modal">
      <div className="admin-modal-content">
        <button className="close-button" onClick={onClose}>×</button> {/* Close button */}
        <h2>Forgot Password</h2>
        <form className="admin-login" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {message && <p className="success-message">{message}</p>}
          {error && <p className="error-message">{error}</p>}
          <button type="submit">Send Password Reset Email</button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;